import { Grid, GridItem } from '@chakra-ui/react';
import { AddButton, Table } from 'components';
import { TableFilters } from 'containers';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  usePaymentSubscriptionList,
  useTableQueryController
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import {
  paymentSubscriptionColumns,
  paymentSubscriptionTableFilters
} from './PaymentSubscriptions.utils';

export const PaymentSubscriptions = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination, onChangeFilters }
  } = useTableQueryController();

  const { data } = usePaymentSubscriptionList(queryFilters);

  const addNewSubscription = () => {
    dispatch(
      openModal({
        id: ModalType.CreateNewSubscription
      })
    );
  };

  return (
    <Grid
      templateAreas={`
        "filter actions"
        "table table"
   `}
      templateColumns="1fr min-content"
      templateRows="min-content 1fr"
      rowGap={6}
      height="100%"
    >
      <GridItem area="filter">
        <TableFilters
          filters={paymentSubscriptionTableFilters}
          onChange={onChangeFilters}
        />
      </GridItem>
      <GridItem area="actions" justifySelf="flex-end">
        <AddButton onClick={addNewSubscription}>
          {t('actions.add_new_subscription')}
        </AddButton>
      </GridItem>
      {data && (
        <GridItem area="table" overflow="hidden">
          <Table
            data={data.data}
            columns={paymentSubscriptionColumns}
            onChangeSort={onChangeSort}
            onChangePagination={onChangePagination}
            totalItems={data.totalItems}
            pagination={queryFilters.pagination}
            hasHorizontalScroll
          />
        </GridItem>
      )}
    </Grid>
  );
};
