export enum RequestStatus {
  Success = 'Success',
  Failure = 'Failure',
  Loading = 'Loading'
}

export enum QueryKey {
  Login = 'login',
  Settings = 'settings',
  Admins = 'admins',
  AdminLoginSession = 'adminLoginSession',
  AdminsMe = 'admins/me',
  Customers = 'customers',
  AllCustomers = 'allCustomers',
  CustomerAdminComments = 'customerAdminComments',
  CustomerLinkedAccounts = 'customerLinkedAccounts',
  CustomerReports = 'customerReports',
  ReportsAgainstCustomer = 'reportsAgainstCustomer',
  CustomerPayments = 'customerPayments',
  CustomerCommentsToUser = 'customerCommentsToUser',
  CustomerCommentsByUser = 'customerCommentsByUser',
  CustomerNotesToUser = 'customerNotesToUser',
  CustomerNotesByUser = 'customerNotesByUser',
  CustomerConversations = 'customerConversations',
  CustomerConversationMessages = 'customerConversationMessages',
  CustomerShouldVerifyConversations = 'customerShouldVerifyConversations',
  CustomerNicknameHistory = 'customerNicknameHistory',
  CustomerEmailHistory = 'customerEmailHistory',
  CustomerMessageImages = 'customerMessageImages',
  CustomerGalleryImages = 'customerGalleryImages',
  CustomerStoryList = 'customerStoryList',
  CustomerBadWords = 'customerBadWords',
  LoginHistory = 'loginHistory',
  FavouritesToUser = 'favouritesToUser',
  FavouritesByUser = 'favouritesByUser',
  LikesToUser = 'likesToUser',
  LikesByUser = 'likesByUser',
  BlackListToUser = 'blackListToUser',
  BlackListByUser = 'blackListByUser',
  LogActivity = 'logActivity',
  ModerationGalleryPhoto = 'moderationGalleryPhoto',
  ModerationComments = 'moderationComments',
  ModerationMotto = 'moderationMotto',
  ModerationAboutMe = 'moderationAboutMe',
  ModerationTotal = 'moderationTotal',
  TicketList = 'ticketList',
  TicketTotal = 'ticketTotal',
  TicketConversation = 'ticketConversation',
  PaymentMethod = 'paymentMethod',
  PaymentSubscription = 'paymentSubscription',
  PaymentTransaction = 'paymentTransaction',
  PaymentSubscriptionTypeByUser = 'paymentSubscriptionTypeByUser',
  PopupManagementList = 'popupManagementList',
  ReportTotal = 'reportTotal',
  ReportCustomerList = 'reportCustomerList',
  ReportAdminList = 'reportAdminList',
  BlogManagementList = 'blogManagementList',
  GeneralSettings = 'generalSettings',
  StatisticRegisteredUsers = 'statisticRegisteredUsers',
  StatisticUsersLogins = 'statisticUsersLogins',
  StatisticReportsByStatus = 'statisticReportsByStatus',
  StatisticPurchasedSubscriptions = 'statisticPurchasedSubscriptions',
  StatisticActiveUsers = 'statisticActiveUsers',
  StatisticReports = 'statisticReports',
  StatisticIncomeMoneyBalance = 'statisticIncomeMoneyBalance',
  StatisticUsersWithEachProgramType = 'statisticUsersWithEachProgramType',
  StatisticCustomerReports = 'statisticCustomerReports',
  StatisticSentMessages = 'statisticSentMessages',
  AllMessageImages = 'allMessageImages',
  AllGalleryImages = 'allGalleryImages',
  AllStoriesList = 'allStoriesList',
  CrmDownloadCSV = 'crmDownloadCSV',
  CrmSendEmail = 'crmSendEmail',
  CrmSendSMS = 'crmSendSMS',
  CrmDeleteUsers = 'crmDeleteUsers',
  CrmBlockUsers = 'crmBlockUsers',
  ParameterOptions = 'ParameterOptions',
  ParameterValues = 'ParameterValues',
  QuickReplies = 'QuickReplies',
  StaticPages = 'staticPages',
  VerificationLink = 'VerificationLink',
  PasswordRecoveryLink = 'PasswordRecoveryLink',
  DomainsConfigList = 'DomainsConfigList'
}

export enum AdminRole {
  SuperAdmin = 'SuperAdmin',
  CountryAdmin = 'CountryAdmin',
  CountryHelpDesk = 'CountryHelpDesk',
  SEOCountryAdmin = 'SEOCountryAdmin',
  CountryFinanceManager = 'CountryFinanceManager',
  CountryHelpDeskManager = 'CountryHelpDeskManager'
}

export enum AdminStatus {
  Activated = 'Activated',
  NotActivated = 'NotActivated'
}

export enum OptionKey {
  AdminRole = 'adminRole',
  AdminStatus = 'adminStatus',
  Country = 'country',
  State = 'state',
  AccountStatus = 'accountStatus',
  SubscriptionType = 'subscriptionType',
  OnlineStatus = 'onlineStatus',
  LinkAccountReason = 'linkAccountReason',
  PaymentMethod = 'paymentMethod',
  ImageStatus = 'imageStatus',
  AdminActivityScope = 'adminActivityScope',
  AdminActivityAction = 'adminActivityAction',
  DeclineReason = 'declineReason',
  SupportSourceType = 'supportSourceType',
  SupportStatusType = 'supportStatusType',
  SupportTicketReason = 'supportTicketReason',
  PopupDisplayPage = 'popupDisplayPage',
  PopupAction = 'popupAction',
  Period = 'period',
  SubscriptionPeriod = 'subscriptionPeriod',
  AdminReportReason = 'adminReportReason',
  ReportReason = 'ReportReason',
  ReportStatus = 'reportStatus',
  ReportType = 'reportType',
  ArticleType = 'articleType',
  Height = 'height',
  HeightInch = 'heightInch',
  SystemLanguage = 'systemLanguage',
  ParameterOptionActivityStatus = 'parameterOptionActivityStatus',
  ParameterOptionType = 'parameterOptionType',
  ParameterOptionCountryRestrictionType = 'parameterOptionCountryRestrictionType',
  TransactionStatus = 'transactionStatus',
  PopupActionLink = 'popupActionLink',
  WelcomePageAlias = 'welcomePageAlias',
  DomainName = 'domainName'
}

export enum AccountStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
  Deleted = 'Deleted'
}

export enum ErrorCode {
  Unknown = 'Unknown',
  AdminDeleted = 'AdminDeleted',
  AdminNotActivated = 'AdminNotActivated',
  AdminNotFound = 'AdminNotFound',
  AdminWrongEmailOrPassword = 'AdminWrongEmailOrPassword',
  AdminCreateEmailExists = 'AdminCreateEmailExists',
  AdminCreateNicknameExists = 'AdminCreateNicknameExists',
  AdminUpdateNicknameExists = 'AdminUpdateNicknameExists',
  ValidationError = 'ValidationError',

  // Customer
  UserCreateEmailExists = 'UserCreateEmailExists',
  UserUpdateEmailExists = 'UserUpdateEmailExists',

  // contact us
  ReplyingTicketUserWhatsAppNumberNotFound = 'ReplyingTicketUserWhatsAppNumberNotFound',
  ReplyingTicketUserTelegramChatNotFound = 'ReplyingTicketUserTelegramChatNotFound',
  ReplyingTicketUserEmailNotFound = 'ReplyingTicketUserEmailNotFound',

  // Payment subscriptions
  SubscriptionDuplicate = 'SubscriptionDuplicate',

  // Articles
  ArticleDuplicate = 'ArticleDuplicate',

  // Static pages
  StaticPageDuplicate = 'StaticPageDuplicate',

  //CRM
  EmailTemplateNotValid = 'EmailTemplateNotValid'
}

export enum PermissionResource {
  Admins = 'admins',
  AdminPassword = 'admin-password',
  AdminType = 'admin-type',
  AdminDashboard = 'statistics',
  Customers = 'users',
  CustomerConversations = 'conversations',
  CustomerMessageImages = 'message-images',
  CustomerStories = 'stories',
  Settings = 'settings',
  SettingsPassword = 'settings-password',
  SettingsCorporateEmail = 'settings-corporate-email',
  SettingsCountryRestrictions = 'settings-country-restrictions',
  Moderation = 'moderation',
  LogActivity = 'log-activities',
  Popups = 'popups',
  ContactUs = 'support',
  Reports = 'reports',
  Payments = 'payments',
  PaymentMethods = 'payment-methods',
  PaymentSubscriptions = 'payment-subscriptions',
  PaymentTransactions = 'payment-transactions',
  Articles = 'articles',
  Content = 'content',
  ParameterOptions = 'parameters-settings',
  ParameterValues = 'parameters-options',
  CRM = 'crm',
  StaticPages = 'static-pages',
  UserPassword = 'user-password',
  GenerateLink = 'generate-link',
  DomainConfigs = 'domain-configs',
  RequestChatAccess = 'request-chat-access'
}

export enum PermissionAction {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Read = 'read'
}

export enum AccountLinkReason {
  SameEmail = 'SameEmail',
  SamePhone = 'SamePhone',
  SamePictures = 'SamePictures',
  SameIP = 'SameIP',
  SameAuthToken = 'SameAuthToken',
  ManuallyLinked = 'ManuallyLinked'
}

export enum ImageStatus {
  Active = 'Active',
  DeletedByCustomer = 'DeletedByCustomer',
  DeletedByAdmin = 'DeletedByAdmin',
  DeclinedByAdmin = 'Declined',
  AutoDeclined = 'AutoDeclined',
  NotAccepted = 'NotAccepted',
  Finished = 'Finished',
  NotUploaded = 'NotUploaded'
}

export enum AssetType {
  Image = 'Image',
  Video = 'Video'
}

export enum AdminActivityScope {
  Authorization = 'Authorization',
  AdministratorsManagement = 'AdministratorsManagement',
  CustomersManagement = 'CustomersManagement',
  GeneralSettings = 'GeneralSettings',
  Moderation = 'Moderation',
  ContactUs = 'ContactUs',
  PaymentManagement = 'PaymentManagement',
  Reports = 'Reports'
}

export enum AdminActivityAction {
  //Authorization
  LoginAdmin = 'LoginAdmin',

  //AdministratorsManagement
  CreateNewAdminAccount = 'CreateNewAdminAccount',
  UpdateAdminAccount = 'UpdateAdminAccount',
  DeleteAdminAccount = 'DeleteAdminAccount',

  //CustomersManagement
  CreateNewUserAccount = 'CreateNewUserAccount',
  UpdateUserAccount = 'UpdateUserAccount',
  UpdateUserAccountLabels = 'UpdateUserAccountLabels',
  UpdateUserAccountStatus = 'UpdateUserAccountStatus',
  DeleteUserAccount = 'DeleteUserAccount',
  CreateAdminCommentOnUser = 'CreateAdminCommentOnUser',

  //GeneralSettings
  UpdateGeneralSettingsData = 'UpdateGeneralSettingsData',

  //Moderation
  ApprovePhoto = 'ApprovePhoto',
  DeclinePhoto = 'DeclinePhoto',
  ApproveComment = 'ApproveComment',
  DeclineComment = 'DeclineComment',

  //ContactUs
  RespondContactUs = 'RespondContactUs',

  //PaymentManagement
  CreateNewTransaction = 'CreateNewTransaction',

  //Reports
  UpdateReportStatus = 'UpdateReportStatus',
  SendReportNotification = 'SendReportNotification'
}

export enum SupportTicketStatus {
  Closed = 'Closed',
  Opened = 'Opened',
  InProgressWaitUser = 'InProgressWaitUser',
  InProgressWaitAdmin = 'InProgressWaitAdmin',
  InProgressGeneral = 'InProgressGeneral',
  None = 'None'
}

export enum SupportSourceType {
  Telegram = 'Telegram',
  Website = 'Website',
  WhatsApp = 'WhatsApp',
  Email = 'Email'
}

export enum PopupStatus {
  Activated = 'Activated',
  NotActivated = 'NotActivated'
}

export enum PopupAction {
  ButtonOne = 'ButtonOne',
  ButtonTwo = 'ButtonTwo',
  X = 'X'
}

export enum WelcomePageAlias {
  WelcomeBase = 'WelcomeBase',
  WelcomeDefault = 'WelcomeDefault',
  WelcomeNg = 'WelcomeNg'
}

export enum LanguageRelatedDisplayName {
  en = 'TranslationEnDisplayName',
  ru = 'TranslationRuDisplayName',
  tr = 'TranslationTrDisplayName',
  he = 'TranslationHeDisplayName',
  de = 'TranslationDeDisplayName',
  fr = 'TranslationFrDisplayName',
  es = 'TranslationEsDisplayName',
  it = 'TranslationItDisplayName',
  el = 'TranslationElDisplayName'
}

export enum LanguageRelatedFileName {
  en = 'TranslationEnFileName',
  ru = 'TranslationRuFileName',
  tr = 'TranslationTrFileName',
  he = 'TranslationHeFileName',
  de = 'TranslationDeFileName',
  fr = 'TranslationFrFileName',
  es = 'TranslationEsFileName',
  it = 'TranslationItFileName',
  el = 'TranslationElFileName'
}

export enum PopupButtonLink {
  Close = 'Close Popup',
  Home = '/',
  Profile = '/profile',
  BlackList = '/black-list',
  Favourites = '/favourites',
  Subscriptions = '/subscriptions',
  ContactUs = '/contact-us',
  Tickets = '/ticketing',
  Blog = '/blog',
  News = '/news',
  Notifications = '/notifications',
  Chat = '/chat',
  Stories = '/stories'
}

export enum PopupDisplayPage {
  Home = 'Home',
  Profile = 'Profile',
  BlackList = 'BlackList',
  Favourites = 'Favourites',
  Subscriptions = 'Subscriptions',
  Tickets = 'Tickets',
  Blog = 'Blog',
  News = 'News',
  Search = 'Search',
  Notifications = 'Notifications',
  Messages = 'Messages',
  Conversation = 'Conversation',
  Chat = 'Chat',
  Stories = 'Stories',
  ContactUs = 'ContactUs'
}

export enum ReportType {
  Profile = 'Profile',
  Gallery = 'Gallery',
  Story = 'Story'
}

export enum AdminReportReason {
  BadWords = 'BadWords',
  Floods = 'Floods'
}

export enum ReportStatus {
  Open = 'Open',
  InProgress = 'InProgress',
  Close = 'Close'
}

export enum ArticleType {
  Blog = 'Blog',
  News = 'News'
}

export enum SystemLanguage {
  EN = 'en',
  RU = 'ru',
  TR = 'tr',
  HE = 'he',
  DE = 'de',
  FR = 'fr',
  ES = 'es',
  IT = 'it',
  EL = 'el'
}

export enum ArticleStatus {
  Activated = 'Activated',
  NotActivated = 'NotActivated'
}

export enum BadWordsType {
  MessageWarn = 'MessageWarn',
  MessageBlock = 'MessageBlock',
  ProfileWarn = 'ProfileWarn'
}

export enum IncomePeriodType {
  Income = 'Income',
  PrevIncome = 'PrevIncome'
}

export enum ParameterType {
  Label = 'Label',
  ProgramType = 'ProgramType',
  ReportReason = 'ReportReason',
  ContactUsReason = 'ContactUsReason',
  Currency = 'Currency',
  NotificationType = 'NotificationType',
  Gender = 'Gender',
  GenderTruncated = 'GenderTruncated',
  CustomerRole = 'CustomerRole',
  CustomerType = 'CustomerType',
  LookForGender = 'LookForGender',
  RelationshipSetting = 'RelationshipSetting',
  BusinessType = 'BusinessType',
  IncomeType = 'IncomeType',
  AvailabilityType = 'AvailabilityType',
  RelationshipTarget = 'RelationshipTarget',
  Interest = 'Interest',
  Language = 'Language',
  EyeColor = 'EyeColor',
  BodyType = 'BodyType',
  HairColor = 'HairColor',
  EthnicityType = 'EthnicityType',
  DrinkingType = 'DrinkingType',
  SmokingType = 'SmokingType',
  FamilyStatus = 'FamilyStatus',
  ChildType = 'ChildType',
  ReligionType = 'ReligionType'
}

export enum ConversationStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Deleted = 'DELETED'
}

export enum PaymentSystem {
  Arkom = 'Arkom',
  Paystack = 'Paystack',
  None = 'None'
}

export enum TransactionStatus {
  Successful = 'Successful',
  Failed = 'Failed'
}

export enum LengthUnits {
  CM = 'CM',
  INCH = 'INCH'
}

export enum PaymentSubscriptionPeriod {
  Month = 'Month',
  Week = 'Week',
  Day = 'Day'
}

export enum ParameterOptionActivityStatus {
  Activated = 'Activated',
  NotActivated = 'NotActivated'
}

export enum ParameterOptionCountryRestrictionType {
  Allowed = 'Allowed',
  Disallowed = 'Disallowed'
}

export enum SchedulerFrequency {
  CurrentWeek = 'CurrentWeek',
  CurrentMonth = 'CurrentMonth',
  CurrentYear = 'CurrentYear',
  Always = 'Always'
}
