import { Wrap } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CalendarIcon,
  DangerIcon,
  DocumentIcon,
  ImageIcon,
  InfoCardProps,
  LabelList,
  LinkIcon,
  MailIcon,
  MessageCircleIcon,
  TruncateText,
  UserIcon
} from 'components';
import { format } from 'date-fns';
import { CustomerPage, CustomerSection, ModalType } from 'enum';
import { ParameterType } from 'enum/api';
import { i18n } from 'i18n';
import { getName } from 'i18n-iso-countries';
import { TFunction } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { openModal } from 'store/modal';
import { ICustomer } from 'types/customer';
import { getAddress, getCustomerLinkNavigation } from 'utils';

import { GetCustomerDetailsInfoFn } from './CustomerDashboard.types';
import { CommentAuthor } from './components';

export const getCustomerAccountInformation = (
  customer: ICustomer,
  t: TFunction,
  navigate: NavigateFunction
): InfoCardProps[] => [
  {
    Icon: CalendarIcon,
    header: format(new Date(customer.system.createdAt), 'HH:mm, dd.MM.yyyy'),
    description: 'Registration date and time'
  },
  {
    Icon: CalendarIcon,
    header: customer.system.lastAccessAt
      ? format(new Date(customer.system.lastAccessAt), 'HH:mm, dd.MM.yyyy')
      : '-',
    description: 'Last login date and time',
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.AdminInteraction,
          section: CustomerSection.LoginHistory
        })
      );
    }
  },
  {
    Icon: CalendarIcon,
    header: customer.system.fullyRegisteredAt
      ? format(new Date(customer.system.fullyRegisteredAt), 'HH:mm, dd.MM.yyyy')
      : '-',
    description: 'Finish registration date and time'
  },
  {
    Icon: UserIcon,
    header: customer.accountStatus,
    description: 'Account status'
  },
  {
    Icon: LinkIcon,
    header: customer.system.linkedAccountsNum,
    description: 'Number of linked accounts',
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.AdminInteraction,
          section: CustomerSection.LinkedAccounts
        })
      );
    }
  },
  ...(customer.system?.deletedAt
    ? [
        {
          Icon: CalendarIcon,
          header: format(
            new Date(customer.system.deletedAt),
            'HH:mm, dd.MM.yyyy'
          ),
          description: 'Deletion/deactivation date and time'
        }
      ]
    : []),
  {
    Icon: DangerIcon,
    header: customer.system.reportsByNum,
    description: 'Number of reports customer submitted about other customers',
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.CustomerReports,
          section: CustomerSection.CustomerReports
        })
      );
    }
  },
  {
    Icon: DangerIcon,
    header: customer.system.reportsToNum,
    description: 'Number of reports other customers submitted about customer',
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.CustomerReports,
          section: CustomerSection.ReportsAgainstCustomer
        })
      );
    }
  },
  {
    Icon: MessageCircleIcon,
    header: customer.system.commentsByNum,
    description: "Number of comments customer wrote to other customers' photos",
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.CustomerInteractions,
          section: CustomerSection.CommentsWrittenByUser
        })
      );
    }
  },
  {
    Icon: MessageCircleIcon,
    header: customer.system.commentsToNum,
    description:
      "Number of comments other customers wrote to customer's photos",
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.CustomerInteractions,
          section: CustomerSection.CommentsWrittenToUser
        })
      );
    }
  },
  {
    Icon: DocumentIcon,
    header: customer.system.notesByNum,
    description: 'Number of notes customer wrote on other customers',
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.CustomerInteractions,
          section: CustomerSection.CustomerNotesByUser
        })
      );
    }
  },
  {
    Icon: DocumentIcon,
    header: customer.system.notesToNum,
    description: 'Number of notes other customers wrote on customer',
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.CustomerInteractions,
          section: CustomerSection.CustomerNotesToUser
        })
      );
    }
  },
  {
    Icon: DocumentIcon,
    header: customer.bio.aboutMe ?? '-',
    description: 'About me',
    onClick: () => {}
  },
  {
    Icon: DocumentIcon,
    header: customer.bio.motto ?? '-',
    description: 'Motto',
    onClick: () => {}
  }
];

export const getCustomerMessages = (
  customer: ICustomer,
  t: TFunction,
  navigate: NavigateFunction
): InfoCardProps[] => [
  {
    Icon: MailIcon,
    header: customer.system.messagesByNum,
    description: 'Number of messages sent from customer'
  },
  {
    Icon: MailIcon,
    header: customer.system.messagesToNum,
    description: 'Number of messages sent to customer'
  },
  {
    Icon: CalendarIcon,
    header: customer.system.lastMessageAt
      ? format(new Date(customer.system.lastMessageAt), 'dd.MM.yyyy')
      : '-',
    description: 'Date and time of last message customer sent'
  },
  {
    Icon: MailIcon,
    header: customer.system.conversationsByNum,
    description: 'Total amount of conversations started by customer'
  },
  {
    Icon: MailIcon,
    header: customer.system.conversationsRepliedNum,
    description:
      'Amount of conversations started by customer that got replied by another participant'
  },
  {
    Icon: ImageIcon,
    header: customer.system.messageImagesNum,
    description: 'Message images',
    onClick: () => {
      navigate(
        getCustomerLinkNavigation({
          customerId: customer.id,
          page: CustomerPage.CustomerContent,
          section: CustomerSection.MessageImages
        })
      );
    }
  }
];

const columnHelper =
  createColumnHelper<ICustomer['lastAdminComments'][number]>();

export const lastAdminCommentsColumns = [
  columnHelper.accessor('admin', {
    cell: ({ getValue, row }) => {
      const {
        original: { createdAt, updatedAt }
      } = row;
      const author = getValue()?.nickname || null;

      return <CommentAuthor date={updatedAt || createdAt} author={author} />;
    },
    header: ({ table }) => table.options.meta?.t('keywords.author'),
    enableSorting: false
  }),
  columnHelper.accessor('text', {
    cell: (info) => <TruncateText>{info.getValue()}</TruncateText>,
    header: ({ table }) => table.options.meta?.t('keywords.comment_text'),
    enableSorting: false
  })
];

export const getCustomerDetailsInfoSchema: GetCustomerDetailsInfoFn = ({
  t,
  customer,
  dispatch,
  getTranslation,
  getOptionMetaForField
}) => {
  const {
    email,
    programType,
    subscriptionType,
    isSubscriptionRenewing,
    subscriptionValidUntil,
    bio: { country, registeredCountry, state, city, phoneNumber },
    system: { labels, registeredDomain },
    config: { lookFor }
  } = customer;

  const labelList = labels.map((item) => {
    const color = getOptionMetaForField<string>(
      ParameterType.Label,
      item,
      'color'
    );

    return {
      label: getTranslation(ParameterType.Label, item),
      color
    };
  });

  return [
    {
      id: 'email',
      title: t('keywords.email'),
      content: email,
      action: {
        label: t('actions.view_history'),
        onClick: () =>
          dispatch(
            openModal({
              id: ModalType.EmailHistory,
              meta: {
                customerId: customer.id
              }
            })
          )
      }
    },
    {
      id: 'phoneNumber',
      title: t('keywords.phone_number'),
      content: phoneNumber
    },
    {
      id: 'address',
      title: t('keywords.address'),
      content: getAddress({
        country,
        state,
        city,
        language: i18n.language
      })
    },
    {
      id: 'registeredCountry',
      title: t('keywords.registered_country'),
      content: getName(registeredCountry || '', i18n.language)
    },
    {
      id: 'registeredDomain',
      title: t('keywords.registered_domain'),
      content: registeredDomain
    },
    {
      id: 'lookingForGender',
      title: t('keywords.looking_for_gender'),
      content: getTranslation(ParameterType.LookForGender, lookFor)
    },
    {
      id: 'programType',
      title: t('keywords.program_type'),
      content: getTranslation(ParameterType.ProgramType, programType)
    },
    ...(subscriptionType
      ? [
          {
            id: 'subscriptionType',
            title: t('keywords.subscription_type'),
            content: subscriptionType
          },
          {
            id: 'isSubscriptionRenewing',
            title: t('keywords.subscription_renewing'),
            content: isSubscriptionRenewing
              ? t('keywords.yes')
              : t('keywords.no')
          },
          {
            id: 'subscriptionValidUntil',
            title: t('keywords.subscription_until'),
            content: subscriptionValidUntil
              ? format(new Date(subscriptionValidUntil), 'HH:mm, dd.MM.yyyy')
              : t('keywords.empty_sign')
          }
        ]
      : []),
    {
      id: 'labels',
      title: t('keywords.labels'),
      content: (
        <Wrap spacing={2}>
          <LabelList
            list={labelList}
            itemProps={{
              colorScheme: 'gray'
            }}
          />
        </Wrap>
      ),
      action: {
        label: t('actions.edit'),
        onClick: () =>
          dispatch(
            openModal({
              id: ModalType.EditLabels,
              meta: {
                labels: customer.system.labels,
                customerId: customer.id
              }
            })
          )
      }
    }
  ];
};
