import { Button } from '@chakra-ui/react';
import { ModalType } from 'enum';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

type Props = {
  subscriptionId: string;
};

export const ActionCell = ({ subscriptionId }: Props): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const onDeleteSubscription = () => {
    dispatch(
      openModal({
        id: ModalType.DeleteSubscription,
        meta: {
          subscriptionId
        }
      })
    );
  };

  return (
    <Button variant="link" colorScheme="red" onClick={onDeleteSubscription}>
      {t('actions.delete')}
    </Button>
  );
};
