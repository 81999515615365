import { FormField, InputElement } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { OptionKey, SystemLanguage } from 'enum/api';
import { DefaultValues } from 'react-hook-form';
import { GetIsHiddenFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { IStaticPagesTableResponse } from '../../../../../pages/StaticPages/types';

import { EditStaticPageFormData } from './EditStaticPage.types';

export const editStaticPageFormSchema = {
  fields: {
    [FormField.Code]: {
      type: InputElement.Input,
      translationKey: 'code',
      hintTranslationKey: 'code_input',
      getPlaceholder: () => 'Code'
    },
    [FormField.Domains]: {
      type: InputElement.MultiSelect,
      translationKey: 'domains',
      getPlaceholder: () => 'Domains',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.DomainName, t, settings)
    },
    [FormField.Language]: {
      type: InputElement.Select,
      translationKey: 'system_language',
      getPlaceholder: () => 'Language',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.SystemLanguage, t, settings)
    },
    [FormField.Title]: {
      type: InputElement.Input,
      translationKey: 'title',
      getPlaceholder: () => 'Title',
      maxLength: 150
    },
    [FormField.MenuTitle]: {
      type: InputElement.Input,
      translationKey: 'menu_title',
      getPlaceholder: () => 'Menu title',
      maxLength: 50
    },
    [FormField.TabTitle]: {
      type: InputElement.Input,
      translationKey: 'tab_title',
      getPlaceholder: () => 'Tab title',
      maxLength: 100
    },
    [FormField.MetaDescription]: {
      type: InputElement.TextArea,
      translationKey: 'meta_description',
      getPlaceholder: () => 'Meta description'
    },
    [FormField.Order]: {
      type: InputElement.NumberInput,
      translationKey: 'order',
      getPlaceholder: () => 'Order',
      min: 0,
      max: 100
    },
    [FormField.Text]: {
      type: InputElement.MdEditor,
      translationKey: 'text',
      getPlaceholder: () => 'Page text'
    },
    [FormField.IsFaqPage]: {
      type: InputElement.Toggle,
      translationKey: 'is_faq_page',
      getPlaceholder: () => 'Is FAQ page'
    },
    [FormField.FaqItems]: {
      type: InputElement.TextArea,
      translationKey: 'faq_items',
      getPlaceholder: () => `JSON array like:
        [
          {
            "question": "",
            "answer": ""
          }
        ]
      `,
      minHeight: 44,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<EditStaticPageFormData>;

        return !formValues[FormField.IsFaqPage];
      }
    }
  },
  gridLayout: {
    templateAreas: `
      "${FormField.Code} ."
      "${FormField.Domains} ."
      "${FormField.Language} ."
      "${FormField.Title} ."
      "${FormField.MenuTitle} ${FormField.TabTitle}"
      "${FormField.MetaDescription} ."
      "${FormField.Order} ${FormField.IsFaqPage}"
      "${FormField.Text} ${FormField.Text}"
      "${FormField.FaqItems} ${FormField.FaqItems}"
    `,
    gridTemplateColumns: '1fr 1fr',
    rowGap: 6,
    columnGap: 10
  }
} as const;

export const editStaticPageValidationSchema: yup.SchemaOf<EditStaticPageFormData> =
  yup.object({
    [FormField.Code]: yup
      .string()
      .required()
      .matches(/^[a-z0-9-]+$/, 'invalid_code')
      .min(3)
      .max(50),
    [FormField.Domains]: yup.array(yup.string().required()).required().min(1),
    [FormField.Language]: yup.mixed().oneOf(getAllEnumValues(SystemLanguage)),
    [FormField.Title]: yup.string().required().max(150),
    [FormField.MenuTitle]: yup.string().required().max(50),
    [FormField.TabTitle]: yup.string().required().max(100),
    [FormField.MetaDescription]: yup.string().required(),
    [FormField.Text]: yup.string().required(),
    [FormField.Order]: yup.number().integer().required(),
    [FormField.IsFaqPage]: yup.boolean().defined(),
    [FormField.FaqItems]: yup
      .string()
      .test('jsonValidation', 'invalid_json', (value) => {
        if (!value) {
          return true;
        }
        try {
          JSON.parse(value);
          return true;
        } catch (error) {
          return false;
        }
      })
      .optional()
  });

export const getDefaultFormValues = (
  staticPage?: IStaticPagesTableResponse
): DefaultValues<EditStaticPageFormData> => ({
  [FormField.Code]: staticPage?.code,
  [FormField.Domains]: staticPage?.domains,
  [FormField.Language]: staticPage?.languageCode,
  [FormField.Title]: staticPage?.title,
  [FormField.MenuTitle]: staticPage?.menuTitle,
  [FormField.TabTitle]: staticPage?.tabTitle,
  [FormField.MetaDescription]: staticPage?.metaDescription,
  [FormField.Text]: staticPage?.text,
  [FormField.Order]: staticPage?.order,
  [FormField.FaqItems]:
    staticPage && JSON.stringify(staticPage.faqItems, null, 2),
  [FormField.IsFaqPage]: !!staticPage?.faqItems?.length
});
