import { ENDPOINTS, PaymentMethodListResponse } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { PaymentSystem } from 'enum';
import { GlobalFilters } from 'types';

const photoUrl =
  'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakedData: PaymentMethodListResponse[] = [
  {
    id: 'id_1',
    title: 'Credit/Debit',
    imageUrl: photoUrl,
    countries: ['BY'],
    showOnWebsite: true,
    paymentSystem: PaymentSystem.Arkom,
    displayName: 'Arkom'
  },
  {
    id: 'id_2',
    title: 'Manual',
    imageUrl: photoUrl,
    countries: ['BY', 'PL'],
    showOnWebsite: true,
    paymentSystem: PaymentSystem.None,
    displayName: 'Manual'
  }
];

export const fetchPaymentMethodList = async ({
  globalFilters
}: {
  globalFilters: GlobalFilters;
}): Promise<PaymentMethodListResponse[]> => {
  const { data } = await axios.post<PaymentMethodListResponse[]>(
    `${ENDPOINTS.PAYMENT_METHOD}/list`,
    serializeBodyFilters({ globalFilters })
  );

  return data;
};
