import { Grid, GridItem } from '@chakra-ui/react';
import { AddButton, Table } from 'components';
import { ContentContainer, TableFilters } from 'containers';
import {
  ModalType,
  PermissionAction,
  PermissionResource,
  TransactionStatus
} from 'enum';
import {
  useAppDispatch,
  useCustomerPayments,
  usePermissions,
  useTableQueryController
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { openModal } from 'store/modal';

import { paymentsColumns, paymentTableFilters } from './CustomerPayments.utils';

export const CustomerPayments = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const { customer } = useOutletContext();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination, onChangeFilters }
  } = useTableQueryController();

  const { data } = useCustomerPayments({
    customerId: customer.id,
    queryFilters: {
      ...queryFilters,
      filter: {
        ...queryFilters.filter,
        statuses: [TransactionStatus.Successful]
      }
    }
  });

  const { hasAccess: hasCreateAccess } = usePermissions({
    resource: PermissionResource.PaymentTransactions,
    actions: PermissionAction.Create
  });

  const addTransaction = () =>
    dispatch(
      openModal({
        id: ModalType.AddCustomerTransaction,
        meta: {
          customerId: customer.id
        }
      })
    );

  return (
    <ContentContainer height="100%">
      <Grid
        templateAreas={`
          "filters actions"
          "table table"
        `}
        templateColumns="1fr auto"
        templateRows="auto 1fr"
        rowGap={6}
        columnGap={6}
        height="100%"
      >
        <GridItem area="filters">
          <TableFilters
            filters={paymentTableFilters}
            onChange={onChangeFilters}
          />
        </GridItem>
        <GridItem area="actions">
          {hasCreateAccess && (
            <AddButton onClick={addTransaction}>
              {t('actions.add_transaction')}
            </AddButton>
          )}
        </GridItem>
        <GridItem area="table">
          {!!data && (
            <Table
              data={data.data}
              columns={paymentsColumns}
              onChangeSort={onChangeSort}
              onChangePagination={onChangePagination}
              pagination={queryFilters.pagination}
              totalItems={data.totalItems}
            />
          )}
        </GridItem>
      </Grid>
    </ContentContainer>
  );
};
