import { CreateDomainConfigRequestData } from 'api';
import {
  FormField,
  InputElement,
  LanguageRelatedDisplayName,
  LanguageRelatedFileName,
  SystemLanguage,
  WelcomePageAlias
} from 'enum';
import { getAllEnumValues } from 'enum-for';
import { OptionKey } from 'enum/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { DefaultValues } from 'react-hook-form';
import { RequiredNotNullable } from 'types';
import { GetIsHiddenFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { CreateDomainConfigFormData } from './CreateDomainConfig.types';

export const createDomainConfigFormSchema = {
  fields: {
    [FormField.Title]: {
      type: InputElement.Input,
      translationKey: 'title',
      getPlaceholder: () => 'Title',
      maxLength: 150
    },
    [FormField.Domain]: {
      type: InputElement.Input,
      translationKey: 'domain',
      hintTranslationKey: 'domain_input',
      getPlaceholder: () => 'Domain',
      maxLength: 100
    },
    [FormField.SubDir]: {
      type: InputElement.Input,
      translationKey: 'sub_dir',
      getPlaceholder: () => '/turkey',
      maxLength: 50
    },
    [FormField.WelcomePageAlias]: {
      type: InputElement.Select,
      translationKey: 'welcome_page_alias',
      getPlaceholder: () => 'Welcome Page Alias',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.WelcomePageAlias, t, settings)
    },
    [FormField.GoogleTagMKey]: {
      type: InputElement.Input,
      translationKey: 'google_t_m_key',
      getPlaceholder: () => 'M-...',
      maxLength: 100
    },
    [FormField.GoogleTagGKey]: {
      type: InputElement.Input,
      translationKey: 'google_t_g_key',
      getPlaceholder: () => 'G-...',
      maxLength: 100
    },
    [FormField.GoogleTagAwKey]: {
      type: InputElement.Input,
      translationKey: 'google_t_aw_key',
      getPlaceholder: () => 'AW-...',
      maxLength: 100
    },
    [FormField.Language]: {
      type: InputElement.Select,
      translationKey: 'default_language',
      getPlaceholder: () => 'Language',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.SystemLanguage, t, settings)
    },
    [FormField.AdditionalFields]: {
      type: InputElement.TextArea,
      translationKey: 'additional_fields',
      getPlaceholder: () => `JSON object like:
        {
          "shouldHideOtherGenders": true,
          "shouldShowReferralCode": true  
        }  
      `,
      minHeight: 44
    },
    [FormField.Assets]: {
      type: InputElement.TextArea,
      translationKey: 'assets',
      getPlaceholder: () => `JSON object like:
        {
          "welcome": {
            "top": "africa.webp",
            "world": {
                "1": "africa.webp",
                "2": "africa.webp",
            ...
            }  
          }  
        }    
      `,
      minHeight: 44
    },
    [FormField.AvailableTranslations]: {
      type: InputElement.MultiSelect,
      translationKey: 'available_translations',
      getPlaceholder: () => 'Available Translations',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.SystemLanguage, t, settings)
    },
    [FormField.TranslationEnDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'English',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.EN
        );
      }
    },
    [FormField.TranslationEnFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'en.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.EN
        );
      }
    },
    [FormField.TranslationRuDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'Русский',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.RU
        );
      }
    },
    [FormField.TranslationRuFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'ru.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.RU
        );
      }
    },
    [FormField.TranslationTrDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'Türkçe',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.TR
        );
      }
    },
    [FormField.TranslationTrFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'tr.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.TR
        );
      }
    },
    [FormField.TranslationHeDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'עברית',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.HE
        );
      }
    },
    [FormField.TranslationHeFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'he.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.HE
        );
      }
    },
    [FormField.TranslationDeDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'Deutsch',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.DE
        );
      }
    },
    [FormField.TranslationDeFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'de.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.DE
        );
      }
    },
    [FormField.TranslationFrDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'French',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.FR
        );
      }
    },
    [FormField.TranslationFrFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'fr.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.FR
        );
      }
    },
    [FormField.TranslationEsDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'Spanish',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.ES
        );
      }
    },
    [FormField.TranslationEsFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'es.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.ES
        );
      }
    },
    [FormField.TranslationItDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'Italian',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.IT
        );
      }
    },
    [FormField.TranslationItFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'it.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.IT
        );
      }
    },
    [FormField.TranslationElDisplayName]: {
      type: InputElement.Input,
      translationKey: 'translation_display_name',
      getPlaceholder: () => 'Greek',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.EL
        );
      }
    },
    [FormField.TranslationElFileName]: {
      type: InputElement.Input,
      translationKey: 'translation_file_name',
      getPlaceholder: () => 'el.json',
      maxLength: 20,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } =
          arg as GetIsHiddenFnArg<CreateDomainConfigFormData>;

        return !formValues[FormField.AvailableTranslations]?.includes(
          SystemLanguage.EL
        );
      }
    }
  },
  gridLayout: {
    templateAreas: `
      "heading heading"
      "${FormField.Title} ."
      "${FormField.Domain} ${FormField.SubDir}"
      ". ."
       "settings settings"
      "${FormField.WelcomePageAlias} ${FormField.GoogleTagMKey}"
      "${FormField.GoogleTagGKey} ${FormField.GoogleTagAwKey}"
      "${FormField.Language} ${FormField.AvailableTranslations}"
      "${FormField.Assets} ${FormField.AdditionalFields}"
      "${FormField.TranslationEnDisplayName} ${FormField.TranslationEnFileName}"
      "${FormField.TranslationRuDisplayName} ${FormField.TranslationRuFileName}"
      "${FormField.TranslationTrDisplayName} ${FormField.TranslationTrFileName}"
      "${FormField.TranslationHeDisplayName} ${FormField.TranslationHeFileName}"
      "${FormField.TranslationDeDisplayName} ${FormField.TranslationDeFileName}"
      "${FormField.TranslationFrDisplayName} ${FormField.TranslationFrFileName}"
      "${FormField.TranslationEsDisplayName} ${FormField.TranslationEsFileName}"
      "${FormField.TranslationItDisplayName} ${FormField.TranslationItFileName}"
      "${FormField.TranslationElDisplayName} ${FormField.TranslationElFileName}"
    `,
    gridTemplateColumns: '1fr 1fr',
    rowGap: 6,
    columnGap: 10
  }
} as const;

export const createDomainConfigValidationSchema: yup.SchemaOf<CreateDomainConfigFormData> =
  yup.object({
    [FormField.Title]: yup.string().required().max(150),
    [FormField.Domain]: yup.string().required().max(100),
    [FormField.SubDir]: yup.string().optional().max(50),
    [FormField.WelcomePageAlias]: yup
      .mixed()
      .oneOf(getAllEnumValues(WelcomePageAlias)),
    [FormField.GoogleTagMKey]: yup.string().required().max(100),
    [FormField.GoogleTagGKey]: yup.string().required().max(100),
    [FormField.GoogleTagAwKey]: yup.string().required().max(100),
    [FormField.Language]: yup.mixed().oneOf(getAllEnumValues(SystemLanguage)),
    [FormField.AdditionalFields]: yup.string().optional(),
    [FormField.Assets]: yup.string().optional(),
    [FormField.AvailableTranslations]: yup
      .array(yup.mixed().oneOf(getAllEnumValues(SystemLanguage)))
      .required()
      .min(1),
    [FormField.TranslationEnDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.EN),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationEnFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.EN),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationRuDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.RU),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationRuFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.RU),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationTrDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.TR),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationTrFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.TR),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationHeDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.HE),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationHeFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.HE),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationDeDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.DE),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationDeFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.DE),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationFrDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.FR),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationFrFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.FR),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationEsDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.ES),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationEsFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.ES),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationItDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.IT),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationItFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.IT),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationElDisplayName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.EL),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      }),
    [FormField.TranslationElFileName]: yup
      .string()
      .when(FormField.AvailableTranslations, {
        is: (value?: string) => value?.includes(SystemLanguage.EL),
        then: yup.string().required().max(20),
        otherwise: yup.string().optional()
      })
  });

export const getDefaultFormValues =
  (): DefaultValues<CreateDomainConfigFormData> => ({
    [FormField.Title]: '',
    [FormField.Domain]: '',
    [FormField.SubDir]: '',
    [FormField.WelcomePageAlias]: '',
    [FormField.GoogleTagMKey]: '',
    [FormField.GoogleTagGKey]: '',
    [FormField.GoogleTagAwKey]: '',
    [FormField.Language]: '',
    [FormField.AdditionalFields]: '',
    [FormField.Assets]: '',
    [FormField.AvailableTranslations]: [],
    [FormField.TranslationEnDisplayName]: '',
    [FormField.TranslationEnFileName]: '',
    [FormField.TranslationRuDisplayName]: '',
    [FormField.TranslationRuFileName]: '',
    [FormField.TranslationTrDisplayName]: '',
    [FormField.TranslationTrFileName]: '',
    [FormField.TranslationHeDisplayName]: '',
    [FormField.TranslationHeFileName]: '',
    [FormField.TranslationDeDisplayName]: '',
    [FormField.TranslationDeFileName]: '',
    [FormField.TranslationFrFileName]: '',
    [FormField.TranslationFrDisplayName]: '',
    [FormField.TranslationEsFileName]: '',
    [FormField.TranslationEsDisplayName]: '',
    [FormField.TranslationItFileName]: '',
    [FormField.TranslationItDisplayName]: '',
    [FormField.TranslationElFileName]: '',
    [FormField.TranslationElDisplayName]: ''
  });

export const serializeRequestData = (
  formData: CreateDomainConfigFormData
): CreateDomainConfigRequestData => {
  const data = formData as RequiredNotNullable<CreateDomainConfigFormData>;

  return pickBy(
    {
      name: data[FormField.Title],
      domain: data[FormField.Domain],
      subDir: data[FormField.SubDir],
      welcomePageAlias: data[FormField.WelcomePageAlias],
      googleTagMKey: data[FormField.GoogleTagMKey],
      googleTagGKey: data[FormField.GoogleTagGKey],
      googleTagAwKey: data[FormField.GoogleTagAwKey],
      defaultTranslation: data[FormField.Language],
      additional: data[FormField.AdditionalFields],
      assets: data[FormField.Assets],
      availableTranslations: data[FormField.AvailableTranslations],
      translations: data[FormField.AvailableTranslations].map(
        (code: SystemLanguage) => ({
          languageCode: code,
          displayName: data[FormField[LanguageRelatedDisplayName[code]]],
          fileName: data[FormField[LanguageRelatedFileName[code]]]
        })
      )
    },
    identity
  ) as CreateDomainConfigRequestData;
};
