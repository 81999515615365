import { GridItem } from '@chakra-ui/react';
import { Search, Table } from 'components';
import { ArticleStatus, ArticleType } from 'enum/api';
import { useArticleList, useTableQueryController } from 'hooks';
import { Fragment } from 'react';

import { blogManagementColumns } from '../BlogManagement.utils';

export const NewsContent = (): JSX.Element => {
  const {
    queryFilters,
    action: { onChangeSort, onChangePagination }
  } = useTableQueryController({
    fixedValues: {
      filter: {
        type: ArticleType.News
      }
    }
  });

  const { data } = useArticleList(queryFilters);

  return (
    <Fragment>
      <GridItem area="search">
        <Search options={[]} onChange={() => {}} />
      </GridItem>
      {data && (
        <GridItem area="table" overflow="hidden">
          <Table
            data={data.data}
            columns={blogManagementColumns}
            onChangeSort={onChangeSort}
            onChangePagination={onChangePagination}
            pagination={queryFilters.pagination}
            totalItems={data.totalItems}
            getIsDisabled={(row) =>
              row.original.status === ArticleStatus.NotActivated
            }
            canInteractWithDisabled
          />
        </GridItem>
      )}
    </Fragment>
  );
};
