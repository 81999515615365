import { Button, Grid, GridItem, HStack, Spinner } from '@chakra-ui/react';
import { AddButton, DownloadIcon, Table } from 'components';
import { TableFilters } from 'containers';
import { ModalType } from 'enum';
import {
  useAppDispatch,
  usePaymentTransactionDownloadCSV,
  usePaymentTransactionList,
  useTableQueryController
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { openModal } from 'store';

import {
  paymentTransactionColumns,
  paymentTransactionTableFilters
} from './PaymentTransactions.utils';

export const PaymentTransactions = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    queryFilters,
    action: { onChangeSort, onChangePagination, onChangeFilters }
  } = useTableQueryController();

  const { data } = usePaymentTransactionList(queryFilters);

  const { mutate, isLoading } = usePaymentTransactionDownloadCSV(queryFilters);

  const addTransaction = () => {
    dispatch(
      openModal({
        id: ModalType.CreateNewTransaction
      })
    );
  };

  const handleDownloadCSV = () => {
    mutate(undefined, {
      onSuccess(data) {
        window.open(data);
      }
    });
  };

  return (
    <Grid
      templateAreas={`
        "filter actions"
        "table table"
   `}
      templateColumns="1fr min-content"
      templateRows="min-content 1fr"
      rowGap={6}
      height="100%"
    >
      <GridItem area="filter">
        <TableFilters
          filters={paymentTransactionTableFilters}
          onChange={onChangeFilters}
        />
      </GridItem>
      <GridItem area="actions" justifySelf="flex-end">
        <HStack spacing={5}>
          <AddButton onClick={addTransaction}>
            {t('actions.add_new_transaction')}
          </AddButton>
          {!isLoading ? (
            <Button
              variant="link"
              leftIcon={<DownloadIcon boxSize="0.6875rem" />}
              iconSpacing="0.6875rem"
              onClick={handleDownloadCSV}
            >
              {t('actions.download_csv_file')}
            </Button>
          ) : (
            <Spinner size="sm" />
          )}
        </HStack>
      </GridItem>
      {data && (
        <GridItem area="table" overflow="hidden">
          <Table
            data={data.data}
            columns={paymentTransactionColumns}
            onChangeSort={onChangeSort}
            onChangePagination={onChangePagination}
            totalItems={data.totalItems}
            pagination={queryFilters.pagination}
            hasHorizontalScroll
          />
        </GridItem>
      )}
    </Grid>
  );
};
