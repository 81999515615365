import {
  Box,
  chakra,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
  Wrap,
  Image,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import {
  Avatar,
  LabelList,
  NavigationLink,
  TextArea,
  TruncateText
} from 'components';
import { InfoCardContainer, ModalContainer } from 'containers';
import { ParameterType } from 'enum/api';
import {
  useParameterTypeMeta,
  useParameterTypeTranslation,
  usePassedTime,
  useBlockCustomer
} from 'hooks';
import { Fragment, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MIDDLE_DOT,
  getAddress,
  getAgeByBirthday,
  getBaseCustomerLinkNavigation,
  subDays
} from 'utils';

import { UserProfileProps } from './UserProfile.types';
import { getCustomerProfileInformation } from './UserProfile.utils';

const CROPPED_IMAGE_POSTFIX = '_cropped';

export const UserProfile = ({
  user,
  compareData
}: UserProfileProps): JSX.Element => {
  const [t, { language }] = useTranslation();
  const { getPassedTime } = usePassedTime();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activePhotoKey, setActivePhotoKey] = useState<string | null>(null);

  const { mutate: blockUser } = useBlockCustomer();

  const { getTranslation } = useParameterTypeTranslation();
  const { getOptionMetaForField } = useParameterTypeMeta();

  const {
    birthDate,
    gender,
    type,
    country,
    city,
    state,
    displayedNickname,
    email,
    avatar,
    labels,
    aboutMe,
    motto,
    online,
    lastOnlineAt,
    hasPremium,
    gallery,
    id: userId
  } = user;

  const accountInfo = getCustomerProfileInformation(user, t);

  const ageLabel = t('keywords.age_short', {
    age: getAgeByBirthday(birthDate)
  });
  const genderLabel = getTranslation(ParameterType.Gender, gender);
  const customerTypeLabel = getTranslation(ParameterType.CustomerType, type);
  const addressLabel = getAddress({
    country,
    city,
    state,
    language
  });

  const userDetails = [
    ageLabel,
    genderLabel,
    customerTypeLabel,
    addressLabel
  ].join(MIDDLE_DOT);

  const labelList = labels.map((item) => {
    const color = getOptionMetaForField<string>(
      ParameterType.Label,
      item,
      'color'
    );

    return {
      label: getTranslation(ParameterType.Label, item),
      color
    };
  });

  const lastOnlineDateTime =
    !online && lastOnlineAt
      ? getPassedTime({
          timeAt: lastOnlineAt,
          hideBefore: subDays(new Date(), 365)
        })
      : null;

  const photos = useMemo(() => {
    if (!gallery?.length) return null;

    const croppedImageKeys = gallery
      .filter(({ imageKey }) => imageKey.includes(CROPPED_IMAGE_POSTFIX))
      .map(({ imageKey }) => imageKey);

    if (!croppedImageKeys.length) return gallery;

    return gallery.filter(
      ({ imageKey }) =>
        !croppedImageKeys.includes(`${imageKey}${CROPPED_IMAGE_POSTFIX}`)
    );
  }, [gallery]);

  const fullImageSrc = useMemo(() => {
    if (!activePhotoKey || !gallery?.length) return;

    const fullImageData = gallery.find(({ imageKey }) =>
      activePhotoKey.includes(CROPPED_IMAGE_POSTFIX)
        ? imageKey === activePhotoKey.slice(0, -CROPPED_IMAGE_POSTFIX.length)
        : imageKey === activePhotoKey
    );

    return fullImageData?.imageUrl;
  }, [gallery, activePhotoKey]);

  return (
    <Fragment>
      <ModalContainer isOpen={isOpen} onClose={onClose} size="2xl">
        <Image src={fullImageSrc} />
      </ModalContainer>
      <VStack
        spacing={6}
        alignItems="stretch"
        overflowX="hidden"
        maxWidth="50%"
      >
        {!!compareData && (
          <Fragment>
            <FormControl>
              <FormLabel>
                <Text>{compareData.current.label}</Text>
              </FormLabel>
              <TextArea
                isReadOnly
                shouldSizeByContent
                resize="unset"
                value={compareData.current.value}
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                <Text>{compareData.prev.label}</Text>
              </FormLabel>
              <TextArea
                isReadOnly
                isDisabled
                shouldSizeByContent
                value={compareData.prev.value}
              />
            </FormControl>
          </Fragment>
        )}
        <VStack alignItems="flex-start">
          <NavigationLink
            shouldUnstyled
            to={getBaseCustomerLinkNavigation(userId)}
          >
            <Avatar
              title={displayedNickname}
              description={email}
              src={avatar}
              hasPremium={hasPremium}
              isOnline={online}
            />
          </NavigationLink>
          <HStack>
            <Text color="subtext" marginRight="15px">
              {userDetails}
            </Text>
            {!!lastOnlineDateTime && (
              <Text textStyle="xs-normal" color="gray.700">
                <Trans
                  i18nKey="templates.last_seen"
                  values={{ datetime: lastOnlineDateTime }}
                >
                  <chakra.span color="subtext" />
                </Trans>
              </Text>
            )}
          </HStack>
        </VStack>
        <InfoCardContainer data={accountInfo} />
        <Grid gridTemplateColumns="1fr 1fr">
          <GridItem>
            <Button
              variant="ghostGray"
              onClick={() => blockUser(userId)}
              color="red"
              marginBottom="1rem"
            >
              {t('actions.block_user')}
            </Button>
            <Box marginBottom={3}>
              <Text textStyle="mds" marginBottom={2}>
                {t('keywords.labels')}
              </Text>
              <Wrap spacing={2}>
                <LabelList
                  list={labelList}
                  itemProps={{
                    colorScheme: 'gray'
                  }}
                />
              </Wrap>
            </Box>
            <Box marginBottom={3}>
              <Text textStyle="mds" marginBottom={1}>
                {t('keywords.something_about_me')}
              </Text>
              <TruncateText>{aboutMe}</TruncateText>
            </Box>
            <Box>
              <Text textStyle="mds" marginBottom={1}>
                {t('keywords.motto')}
              </Text>
              <TruncateText>{motto}</TruncateText>
            </Box>
          </GridItem>
          {photos?.length && (
            <GridItem>
              <VStack alignItems="flex-start" width="410px">
                <Text textStyle="mds" marginBottom={2}>
                  {t('keywords.profile_gallery')}
                </Text>
                <Grid
                  templateColumns="repeat(3, auto)"
                  rowGap={2}
                  columnGap={2}
                >
                  {photos.map(({ imageUrl, imageKey }, idx) => (
                    <GridItem
                      key={idx}
                      onClick={() => {
                        setActivePhotoKey(imageKey);
                        onOpen();
                      }}
                    >
                      <Image
                        src={imageUrl}
                        borderRadius="xl"
                        width="130px"
                        height="130px"
                      />
                    </GridItem>
                  ))}
                </Grid>
              </VStack>
            </GridItem>
          )}
        </Grid>
      </VStack>
    </Fragment>
  );
};
