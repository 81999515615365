import { chakra, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { OnlineMark } from 'components';
import { usePassedTime } from 'hooks';
import { i18n } from 'i18n';
import { Trans } from 'react-i18next';
import { IUserFeedInfo } from 'types/customer';
import { getAddress, getAgeByBirthday, subDays } from 'utils';

export const UserCardDescription = ({
  user
}: {
  user: IUserFeedInfo;
}): JSX.Element => {
  const {
    displayedNickname,
    country,
    city,
    state,
    birthDate,
    online,
    lastOnlineAt
  } = user;

  const { getPassedTime } = usePassedTime();

  const lastOnlineDateTime =
    !online && lastOnlineAt
      ? getPassedTime({
          timeAt: lastOnlineAt,
          hideBefore: subDays(new Date(), 30)
        })
      : null;
  const address = getAddress({
    country,
    state,
    city,
    language: i18n.language
  });

  return (
    <VStack spacing={0} alignItems="stretch">
      <HStack spacing={1} marginRight={10}>
        <Flex whiteSpace="nowrap" overflow="hidden" alignItems="center">
          {online && <OnlineMark isOnline={online} boxSize={2} />}
          <Text
            textStyle="sm-semibold"
            overflow="hidden"
            textOverflow="ellipsis"
            color="#F73E52"
            fontWeight="normal"
          >
            {displayedNickname}
          </Text>
          <Text textStyle="sm-semibold">{`, ${getAgeByBirthday(
            birthDate
          )}`}</Text>
        </Flex>
      </HStack>
      <HStack marginTop="-5px">
        <Text
          textStyle="xs-normal"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          color="#605F67"
          fontWeight="600"
        >
          {address}
        </Text>
      </HStack>
      {!!lastOnlineDateTime && (
        <Text textStyle="xs-normal" color="#605F67" fontWeight="600">
          <Trans
            i18nKey="templates.last_seen"
            values={{ datetime: lastOnlineDateTime }}
          >
            <chakra.span color="subtext" fontWeight="medium" />
          </Trans>
        </Text>
      )}
    </VStack>
  );
};
