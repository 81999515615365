import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchDeletePaymentSubscription } from 'api';
import { QueryKey } from 'enum/api';

export const useDeletePaymentSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(fetchDeletePaymentSubscription, {
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.PaymentSubscription]);
    }
  });
};
