import { createColumnHelper } from '@tanstack/react-table';
import { CustomerNicknameHistoryResponse } from 'api';
import { getDateFormattedString } from 'utils';

const columnHelper = createColumnHelper<CustomerNicknameHistoryResponse>();

export const nicknameColumns = [
  columnHelper.accessor('displayedNickname', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.nickname'),
    enableSorting: true
  }),
  columnHelper.accessor('setAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({
        t: table.options.meta?.t,
        date: getValue()
      }),
    header: ({ table }) =>
      table.options.meta?.t('keywords.nickname_date_and_time'),
    enableSorting: true
  })
];
