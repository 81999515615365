import countries from 'country-state-city/lib/country';
import { SystemLanguage } from 'enum';

import { IGoogleService } from './GoogleService.types';
import { getPlaceDetails, getPlacePredictions } from './helpers';

const additionalNames: Record<string, Record<string, string[]> | undefined> = {
  en: { us: ['usa'] },
  ru: { us: ['сша'] }
};

const countryNamesByLocaleAndCode = Object.fromEntries(
  ['en', 'ru', 'tr', 'he', 'de', 'fr', 'es', 'it', 'el'].map((language) => {
    const regionNames = new Intl.DisplayNames([language], {
      type: 'region'
    });

    const additional = additionalNames[language];

    const countryNamesByCode = Object.fromEntries(
      countries.getAllCountries().map(({ isoCode, name: enName }) => {
        const name = regionNames.of(isoCode) || enName;

        return [
          isoCode.toLowerCase(),
          [
            isoCode.toLowerCase(),
            name.toLowerCase(),
            ...[
              name
                .toLowerCase()
                .replace(/\(.*?\)/g, '')
                .split(' ')
                .map((str) => str[0])
                .join(''),
              ...(additional?.[isoCode.toLowerCase()] || [])
            ].filter((value) => value && value.length > 1)
          ]
        ];
      })
    );

    return [language, countryNamesByCode];
  })
);

let sessionToken: google.maps.places.AutocompleteSessionToken | null = null;

const tryToGetToken = () => {
  try {
    sessionToken = new google.maps.places.AutocompleteSessionToken();
  } catch (error) {
    setTimeout(tryToGetToken, 1000);
  }
};

tryToGetToken();

export const GoogleService: IGoogleService = {
  getPlacePredictions: async (language, { filterCountries, ...request }) => {
    if (!sessionToken) {
      return null;
    }

    const predictions = await getPlacePredictions({
      language,
      sessionToken,
      ...request
    });

    if (filterCountries?.length && countryNamesByLocaleAndCode) {
      const countryNamesByCode = countryNamesByLocaleAndCode[language];

      const filter = filterCountries
        .map((code) => countryNamesByCode?.[code.toLowerCase()] ?? [])
        .flat();

      return (
        predictions?.filter(({ terms }) => {
          const country = terms[terms.length - 1]?.value.toLowerCase();

          return !filter.includes(country);
        }) || null
      );
    }

    return predictions;
  },
  getFormattedPlaceDetails: async (language, request, fn) => {
    if (!sessionToken) {
      return null;
    }

    const placeDetails = await getPlaceDetails({
      language,
      sessionToken,
      ...request
    });

    return placeDetails && fn(placeDetails);
  },
  getCountryNamesByLocaleAndCode: (
    language: SystemLanguage,
    code: string
  ): string => {
    const countryName =
      countryNamesByLocaleAndCode[language][code.toLowerCase()][1];

    return `${countryName.charAt(0).toUpperCase()}${countryName.slice(1)}`;
  }
};
