import { ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { useDeletePaymentSubscription, useModalActions } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ParamType } from 'types';

export const DeleteSubscription = (): JSX.Element => {
  const [t] = useTranslation();

  const { mutate, isLoading, reset } = useDeletePaymentSubscription();

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.DeleteSubscription,
    {
      onClose: reset
    }
  );

  const onConfirm = (onClose: () => void) => {
    if (!meta?.subscriptionId) {
      return;
    }

    mutate(
      { subscriptionId: meta.subscriptionId },
      {
        onSuccess: onClose
      }
    );
  };

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.no'),
      variant: 'ghostGray',
      disabled: isLoading
    },
    {
      text: t('actions.yes'),
      colorScheme: 'secondary',
      isLoading,
      isPreventClose: true,
      onClick: onConfirm
    }
  ];

  return (
    <ModalContainer
      header={t('attribute.title.delete_subscription')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      {t('attribute.description.basic_action_question', {
        action: t('actions.delete_subscription').toLowerCase()
      })}
    </ModalContainer>
  );
};
