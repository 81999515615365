import { ENDPOINTS, WithPagination } from 'api';
import { serializeBodyFilters } from 'api/api.utils';
import axios from 'axios';
import { QueryFilters } from 'types';
import { ICustomerPayments } from 'types/customer';

import { CustomerPaymentsResponse } from './Payments.types';

// This data is here for testing purpose only and reflects the format of data in DB
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakeData: CustomerPaymentsResponse[] = [
  {
    id: 'id_1',
    createdAt: new Date().toISOString(),
    amount: 45,
    currency: 'USD, $',
    comment: 'Some comment',
    user: {
      id: 'user_1',
      nickname: 'user1'
    },
    admin: {
      id: 'admin_1',
      nickname: 'admin1'
    },
    paymentMethodDisplayName: 'card',
    programType: 'Free',
    subscriptionType: 'sub_type'
  }
];

export const fetchCustomerPaymentList = async ({
  customerId,
  queryFilters
}: {
  customerId: string;
  queryFilters: QueryFilters;
}): Promise<WithPagination<ICustomerPayments[]>> => {
  const { data } = await axios.post<WithPagination<CustomerPaymentsResponse[]>>(
    `${ENDPOINTS.PAYMENT_TRANSACTION}/by-user/${customerId}/list`,
    serializeBodyFilters({ queryFilters })
  );

  return data;
};
