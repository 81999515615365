import {
  Avatar as ChakraAvatar,
  Grid,
  GridItem,
  HStack,
  Text
} from '@chakra-ui/react';
import { OnlineMark, PremiumCrownIcon } from 'components';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  description: string;
  onClick?: () => void;
  src?: string | null;
  hasPremium?: boolean;
  isOnline?: boolean;
};

export const Avatar = ({
  title,
  description,
  onClick,
  src,
  hasPremium,
  isOnline
}: Props): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Grid
      templateAreas={`"avatar title"
                    "avatar description"
    `}
      rowGap={1}
      columnGap={3}
      alignItems="center"
      justifyContent="flex-start"
      justifyItems="flex-start"
      onClick={onClick}
      cursor={!!onClick ? 'pointer' : 'inherit'}
    >
      <GridItem area="avatar">
        <ChakraAvatar name={title} src={src || undefined} />
      </GridItem>
      <GridItem area="title">
        <HStack>
          {isOnline && (
            <OnlineMark isOnline={isOnline} boxSize={2} marginRight="-2px" />
          )}
          <Text textStyle="xss" overflowWrap="anywhere">
            {title}
          </Text>
          {hasPremium && (
            <HStack spacing={0.5}>
              <Text textStyle="xsrg" color="red">
                {t('keywords.premium')}
              </Text>
              <PremiumCrownIcon fontSize="xss" marginBottom="2px" />
            </HStack>
          )}
        </HStack>
      </GridItem>
      <GridItem area="description" color="subtext">
        <Text textStyle="xsrg" overflowWrap="anywhere">
          {description}
        </Text>
      </GridItem>
    </Grid>
  );
};
