import { ENDPOINTS } from 'api/endpoints';
import axios from 'axios';
import { PopupButtonLink, OnlineStatus, Period, WelcomePageAlias } from 'enum';
import { getAllEnumValues } from 'enum-for';
import {
  AdminActivityAction,
  AdminActivityScope,
  AdminReportReason,
  ArticleType,
  ImageStatus,
  OptionKey,
  ParameterOptionActivityStatus,
  ParameterOptionCountryRestrictionType,
  PopupAction,
  PopupDisplayPage,
  ReportStatus,
  ReportType,
  SupportSourceType,
  SupportTicketStatus,
  SystemLanguage,
  TransactionStatus
} from 'enum/api';
import range from 'lodash/range';
import { serializeTMSettings } from 'utils';

import {
  CustomSettings,
  Settings,
  SettingsResponse,
  TableManagementSettings
} from './Settings.types';

const getCustomOptions = (
  tmOptions: TableManagementSettings
): CustomSettings => ({
  [OptionKey.OnlineStatus]: getAllEnumValues(OnlineStatus),
  [OptionKey.ImageStatus]: getAllEnumValues(ImageStatus),
  [OptionKey.TransactionStatus]: getAllEnumValues(TransactionStatus),
  [OptionKey.AdminActivityScope]: getAllEnumValues(AdminActivityScope),
  [OptionKey.AdminActivityAction]: getAllEnumValues(AdminActivityAction),
  [OptionKey.SupportSourceType]: getAllEnumValues(SupportSourceType),
  [OptionKey.SupportStatusType]: getAllEnumValues(SupportTicketStatus),
  [OptionKey.PopupDisplayPage]: getAllEnumValues(PopupDisplayPage),
  [OptionKey.PopupAction]: getAllEnumValues(PopupAction),
  [OptionKey.WelcomePageAlias]: getAllEnumValues(WelcomePageAlias),
  [OptionKey.PopupActionLink]: getAllEnumValues(PopupButtonLink),
  [OptionKey.Period]: getAllEnumValues(Period),
  [OptionKey.Height]: range(140, 221),
  [OptionKey.HeightInch]: range(
    Math.round(140 / 2.54),
    Math.round(221 / 2.54)
  ).map((value) => Math.round(value * 2.54)),
  [OptionKey.AdminReportReason]: getAllEnumValues(AdminReportReason),
  [OptionKey.ReportStatus]: getAllEnumValues(ReportStatus),
  [OptionKey.ReportType]: getAllEnumValues(ReportType),
  [OptionKey.ArticleType]: getAllEnumValues(ArticleType),
  [OptionKey.ParameterOptionActivityStatus]: getAllEnumValues(
    ParameterOptionActivityStatus
  ),
  [OptionKey.ParameterOptionCountryRestrictionType]: getAllEnumValues(
    ParameterOptionCountryRestrictionType
  ),
  [OptionKey.ParameterOptionType]: Object.keys(tmOptions)
});

export const fetchSettings = async (): Promise<Settings> => {
  const [{ data: baseOptions }, { data: tableManagementOptions }] =
    await Promise.all([
      axios.get<SettingsResponse>(ENDPOINTS.PERMISSIONS_OPTIONS),
      axios.get<TableManagementSettings>(ENDPOINTS.PARAMETERS_OPTIONS)
    ]);

  return {
    ...baseOptions,
    ...serializeTMSettings(tableManagementOptions),
    ...getCustomOptions(tableManagementOptions)
  };
};
