import { Flex, Tag } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { TruncateText } from 'components';
import { getDateFormattedString } from 'utils';

import { ActionCell } from './components';
import { IStaticPagesTableResponse } from './types';

const columnHelper = createColumnHelper<IStaticPagesTableResponse>();

export const columns = [
  columnHelper.accessor('code', {
    cell: (info) => (
      <Tag colorScheme="status.gray" size="md">
        {info.getValue()}
      </Tag>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.code'),
    enableSorting: false
  }),
  columnHelper.accessor('domains', {
    cell: ({ getValue }) => (
      <Flex gap={2}>
        {getValue().map((el) => (
          <TruncateText key={el}>{el}</TruncateText>
        ))}
      </Flex>
    ),
    header: ({ table }) => table.options.meta?.t('keywords.domains'),
    enableSorting: false
  }),
  columnHelper.accessor('languageCode', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.language'),
    enableSorting: false
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue, table }) =>
      getDateFormattedString({ t: table.options.meta?.t, date: getValue() }),
    header: ({ table }) => table.options.meta?.t('keywords.date_and_time'),
    enableSorting: true
  }),
  columnHelper.accessor('menuTitle', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.menu_title'),
    enableSorting: false
  }),
  columnHelper.accessor('tabTitle', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.tab_title'),
    enableSorting: false
  }),
  columnHelper.accessor('title', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.title'),
    enableSorting: false
  }),
  columnHelper.accessor('order', {
    cell: (info) => info.getValue(),
    header: ({ table }) => table.options.meta?.t('keywords.order'),
    enableSorting: false
  }),
  columnHelper.accessor('faqItems', {
    cell: (info) => !!info.getValue()?.length && '[...]',
    header: ({ table }) => table.options.meta?.t('keywords.faq_items'),
    enableSorting: false
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => <ActionCell staticPage={row.original} />,
    header: () => null,
    enableSorting: false
  })
];
