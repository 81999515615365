import { AspectRatio, Box, Image, Text, Center } from '@chakra-ui/react';
import { LikeIcon } from 'components';
import { IUserFeedInfo } from 'types/customer';

export const UserCardBody = ({
  user,
  imageUrl
}: {
  user: IUserFeedInfo;
  imageUrl: string;
}): JSX.Element => {
  const { motto } = user;

  return (
    <Box position="relative" width="185px" height="185px">
      <AspectRatio
        ratio={1}
        width="100%"
        borderRadius="0.625rem"
        overflow="hidden"
      >
        <Image src={imageUrl} alignSelf="auto" />
      </AspectRatio>
      <Box
        as="span"
        display="block"
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        height={10}
        background="linear-gradient(180deg, rgba(24, 24, 31, 0) 0%, rgba(24, 24, 31, 0.8) 100%)"
        borderBottomRadius="0.625rem"
      />
      <Center
        position="absolute"
        bottom="-1.375rem"
        right={0.5}
        width="40px"
        height="40px"
        background="white"
        borderRadius="50%"
      >
        <LikeIcon fontSize="3xl" color="#F73E52" />
      </Center>
      {motto && (
        <Text
          noOfLines={2}
          position="absolute"
          bottom={1}
          left={1}
          right="3.25rem"
          textStyle="xxs-normal"
          color="white"
        >
          {motto}
        </Text>
      )}
    </Box>
  );
};
